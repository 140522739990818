import colors from "vuetify/lib/util/colors";

export const defaultTheme = {
  dark: false,
  themes: {
    light: {
      primary: "#1565c0",
      secondary: colors.grey.base,
      accent: colors.shades.black,
      error: colors.red.accent3,
      text: "#000000",
      contrastText: "#ffffff",
      warning: colors.yellow.darken1,
      warning_darken: colors.yellow.darken3,
    },
    dark: {
      primary: "#1565c0",
      secondary: colors.grey.base,
      accent: colors.shades.black,
      error: colors.red.accent3,
      text: "#000000",
      contrastText: "#ffffff",
      warning: colors.yellow.darken1,
      warning_darken: colors.yellow.darken3,
    },
  },
};
