export default {
  activeMenu(context, payload) {
    context.commit("closeMenu", payload);
  },
  activeLoader(context, payload) {
    context.commit("closeLoader", payload);
  },
  PersistUser(context, payload) {
    context.commit("UserLogin", payload);
  },
  setAgency(context, payload) {
    context.commit("setAgency", payload);
  },
  clearSelectedPackpages(context, payload) {
    context.commit("clearSelectedPackpages", payload);
  },
  showMessage(context, payload) {
    context.commit("showMessage", payload);
  },
  updateCurrentCashboxState(context, payload) {
    context.commit("updateCurrentCashboxState", payload);
  },
  updateShowModalDriverReleases(context, payload) {
    context.commit("updateShowModalDriverReleases", payload);
  },
  showCashboxModalOpenCashbox(context, payload) {
    context.commit("showCashboxModalOpenCashbox", payload);
  },
  showCashboxModalReopenCashbox(context, payload) {
    context.commit("showCashboxModalReopenCashbox", payload);
  },
  showMessagecashboxLastOpened(context, payload) {
    context.commit("showMessagecashboxLastOpened", payload);
  },
  showMessageCashboxNotOpenToday(context, payload) {
    context.commit("showMessageCashboxNotOpenToday", payload);
  },
  setLargeHeader(context, payload) {
    context.commit("setLargeHeader", payload);
  },
};
