import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "logar",
      component: () => import("./views/login/Index.vue"),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./views/dashboard/index.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/login/Index.vue"),
    },
    {
      path: "/cadastros",
      name: "cadastros",
      component: () => import("./views/cadastros/Index.vue"),
      children: [
        {
          path: "/cadastros/operadores",
          name: "gerenciar-operadores",
          component: () => import("./views/cadastros/operadores/Main.vue"),
        },
        {
          path: "/cadastros/operadores/new",
          name: "novo-operador",
          component: () => import("./views/cadastros/operadores/Create.vue"),
        },
        {
          path: "/cadastros/operadores/editar/:id",
          name: "editar-operadores",
          props: true,
          component: () => import("./views/cadastros/operadores/Create.vue"),
        },
        {
          path: "/cadastros/clientes",
          name: "gerenciar-clientes",
          component: () => import("./views/cadastros/clientes/Main.vue"),
        },
        {
          path: "/cadastros/clientes/new",
          name: "criar-clientes",
          component: () => import("./views/cadastros/clientes/Create.vue"),
        },
        {
          path: "/cadastros/clientes/editar/:id",
          name: "editar-clientes",
          props: true,
          component: () => import("./views/cadastros/clientes/Create.vue"),
        },
        {
          path: "/cadastros/formas-pagamento",
          name: "gerenciar-formas-pagamento",
          component: () =>
            import("./views/cadastros/formas_pagamento/Main.vue"),
        },
        {
          path: "/cadastros/formas-pagamento/new",
          name: "criar-formas-pagamento",
          component: () =>
            import("./views/cadastros/formas_pagamento/Create.vue"),
        },
        {
          path: "/cadastros/formas-pagamento/editar/:id",
          name: "editar-formas-pagamento",
          props: true,
          component: () =>
            import("./views/cadastros/formas_pagamento/Create.vue"),
        },
        {
          path: "/cadastros/tipos-servico",
          name: "gerenciar-tipo-servico",
          component: () => import("./views/cadastros/tipo_servico/Main.vue"),
        },
        {
          path: "/cadastros/tipos-servico/new",
          name: "criar-tipo-servico",
          component: () => import("./views/cadastros/tipo_servico/Create.vue"),
        },
        {
          path: "/cadastros/tipos-servico/editar/:id",
          name: "editar-tipo-servico",
          props: true,
          component: () => import("./views/cadastros/tipo_servico/Create.vue"),
        },
        {
          path: "/cadastros/empresas",
          name: "gerenciar-empresa",
          component: () => import("./views/cadastros/empresas/Main.vue"),
        },
        {
          path: "/cadastros/empresas/new",
          name: "criar-empresa",
          component: () => import("./views/cadastros/empresas/Create.vue"),
        },
        {
          path: "/cadastros/empresas/editar/:id",
          name: "editar-empresa",
          props: true,
          component: () => import("./views/cadastros/empresas/Create.vue"),
        },
        {
          path: "/cadastros/motoristas",
          name: "gerenciar-motorista",
          component: () => import("./views/cadastros/motoristas/Main.vue"),
        },
        {
          path: "/cadastros/motoristas/new",
          name: "criar-motorista",
          component: () => import("./views/cadastros/motoristas/Create.vue"),
        },
        {
          path: "/cadastros/motoristas/editar/:id",
          name: "editar-motorista",
          props: true,
          component: () => import("./views/cadastros/motoristas/Create.vue"),
        },
        {
          path: "/cadastros/tipos-de-venda",
          name: "gerenciar-tipo-venda",
          component: () => import("./views/cadastros/tipos_venda/Main.vue"),
        },
        {
          path: "/cadastros/tipos-de-venda/new",
          name: "criar-tipo-venda",
          component: () => import("./views/cadastros/tipos_venda/Create.vue"),
        },
        {
          path: "/cadastros/tipos-de-venda/editar/:id",
          name: "editar-tipo-venda",
          props: true,
          component: () => import("./views/cadastros/tipos_venda/Create.vue"),
        },
        {
          path: "/cadastros/naturezas-operacao",
          name: "gerenciar-naturezas-operacao",
          component: () => import("./views/cadastros/natureza_op/Main.vue"),
        },
        {
          path: "/cadastros/naturezas-operacao/new",
          name: "criar-naturezas-operacao",
          component: () => import("./views/cadastros/natureza_op/Create.vue"),
        },
        {
          path: "/cadastros/naturezas-operacao/editar/:id",
          name: "editar-naturezas-operacao",
          props: true,
          component: () => import("./views/cadastros/natureza_op/Create.vue"),
        },
      ],
    },
    {
      path: "/agencias",
      name: "agencias",
      component: () => import("./views/agencias/Index.vue"),
      children: [
        {
          path: "/agencias/gerenciar",
          name: "gerenciar-gencias",
          component: () => import("./views/agencias/Main.vue"),
        },
        {
          path: "/agencias/new",
          name: "nova-agencia",
          props: true,
          component: () => import("./views/agencias/Create.vue"),
        },
        {
          path: "/agencia/editar/:id",
          name: "editar-agencia",
          props: true,
          component: () => import("./views/agencias/Create.vue"),
        },
        {
          path: "/agencias/comissoes",
          name: "gerenciar-comissoes",
          component: () => import("./views/agencias/comissoes/Main.vue"),
        },
        {
          path: "/agencias/comissoes/editar/:id",
          name: "editar-comissao",
          component: () => import("./views/agencias/comissoes/Create.vue"),
        },
      ],
    },
    {
      path: "/encomendas",
      name: "encomendas",
      component: () => import("./views/pdv/encomendas/Index.vue"),
      children: [
        {
          path: "/encomendas/gerenciar",
          name: "gerenciar-encomendas",
          component: () => import("./views/pdv/encomendas/talao/Main.vue"),
        },
        {
          path: "/encomendas/movimentar",
          name: "movimentar-encomendas",
          component: () => import("./views/pdv/encomendas/Movimentar.vue"),
        },
        {
          path: "/encomendas/new",
          name: "nova-encomenda",
          props: true,
          component: () => import("./views/pdv/encomendas/talao/Create.vue"),
        },
        {
          path: "/encomendas/edit/:id",
          name: "editar-encomenda",
          props: true,
          component: () => import("./views/pdv/encomendas/talao/Create.vue"),
        },
        {
          path: "/encomendas-padrao-cte/gerenciar",
          name: "gerenciar-encomendas-padrao-cte",
          component: () => import("./views/pdv/encomendas/padraocte/Main.vue"),
        },
        {
          path: "/encomendas-padrao-cte/new",
          name: "nova-encomenda-padrao-cte",
          props: true,
          component: () => import("./views/pdv/encomendas/padraocte/Create"),
        },
        {
          path: "/encomendas/clientes",
          name: "clientes",
          props: true,
          component: () => import("./views/pdv/encomendas/clientes/Main.vue"),
        },
        {
          path: "/encomendas/clientes/new",
          name: "novo-cliente",
          props: true,
          component: () => import("./views/pdv/encomendas/clientes/Create.vue"),
        },
        {
          path: "/encomendas/clientes/editar/:id",
          name: "novo-cliente-encomenda-editar",
          props: true,
          component: () => import("./views/pdv/encomendas/clientes/Create.vue"),
        },
        {
          path: "/encomendas/mapa-de-encomendas",
          name: "mapa-de-encomendas",
          props: true,
          component: () => import("./views/pdv/mapa_de_encomendas/Main.vue"),
        },
      ],
    },
    {
      path: "/preco",
      name: "preco",
      component: () => import("./views/preco/Index.vue"),
      children: [
        {
          path: "/preco/tarifas",
          name: "criar-tarifa",
          component: () => import("./views/preco/tarifas/Main.vue"),
        },
        {
          path: "/preco/descontos/gerenciar",
          name: "gerenciar-descontos",
          component: () => import("./views/preco/descontos/Main.vue"),
        },
        {
          path: "/preco/descontos/new",
          name: "Novo-desconto",
          props: true,
          component: () => import("./views/preco/descontos/Create.vue"),
        },
        {
          path: "/preco/descontos/editar/:id",
          name: "editar-descontos",
          props: true,
          component: () => import("./views/preco/descontos/Create.vue"),
        },
        {
          path: "/preco/gratuidades/gerenciar",
          name: "gerenciar-gratuidades",
          component: () => import("./views/preco/gratuidades/Main.vue"),
        },
        {
          path: "/preco/gratuidades/new",
          name: "nova-gratuidade",
          component: () => import("./views/preco/gratuidades/Create.vue"),
        },
        {
          path: "/preco/gratuidades/editar/:id",
          name: "editar-gratuidades",
          props: true,
          component: () => import("./views/preco/gratuidades/Create.vue"),
        },
      ],
    },
    {
      path: "/escalas/onibus",
      name: "gerenciar-escala-onibus",
      component: () => import("./views/escalas/onibus/Main.vue"),
    },
    {
      path: "/escalas/onibus/new",
      name: "nova-escala-onibus",
      component: () => import("./views/escalas/onibus/Create.vue"),
    },
    {
      path: "/escalas/onibus/editar/:id",
      name: "editar-escala-onibus",
      props: true,
      component: () => import("./views/escalas/onibus/Create.vue"),
    },
    {
      path: "/escalas/motoristas",
      name: "gerenciar-escala-motorista",
      component: () => import("./views/escalas/motoristas/Main.vue"),
    },
    {
      path: "/escalas/motoristas/new",
      name: "nova-escala-motorista",
      component: () => import("./views/escalas/motoristas/Create.vue"),
    },
    {
      path: "/escalas/motoristas/editar/:id",
      name: "editar-escala-motorista",
      props: true,
      component: () => import("./views/escalas/motoristas/Create.vue"),
    },
    {
      path: "/cadastros-operacionais",
      name: "cadastros-operacionais",
      component: () => import("./views/cad_op/Index.vue"),
      children: [
        {
          path: "/cadastros-operacionais/checktrechos/gerenciar",
          name: "gerenciar-checar-total",
          component: () => import("./views/cad_op/pdv_test/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/checktrechos/new",
          name: "nova-checar-total",
          props: true,
          component: () => import("./views/cad_op/pdv_test/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/checktrechos/editar/:id",
          name: "editar-checar-total",
          props: true,
          component: () => import("./views/cad_op/pdv_test/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/localidades/gerenciar",
          name: "gerenciar-localizadades",
          component: () => import("./views/cad_op/localidades/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/localidades/new",
          name: "nova-localidades",
          props: true,
          component: () => import("./views/cad_op/localidades/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/localidades/editar/:id",
          name: "editar-localidades",
          props: true,
          component: () => import("./views/cad_op/localidades/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/linhas/gerenciar",
          name: "gerenciar-linhas",
          component: () => import("./views/cad_op/linhas/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/linhas/new",
          name: "criar-linhas",
          component: () => import("./views/cad_op/linhas/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/linhas/editar/:id",
          name: "editar-linhas",
          props: true,
          component: () => import("./views/cad_op/linhas/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/onibus/gerenciar",
          name: "gerenciar-onibus",
          component: () => import("./views/cad_op/onibus/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/onibus/new",
          name: "novo-onibus",
          component: () => import("./views/cad_op/onibus/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/onibus/editar/:id",
          name: "editar-onibus",
          props: true,
          component: () => import("./views/cad_op/onibus/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/onibus/layout/gerenciar",
          name: "gerenciar-layout",
          component: () => import("./views/cad_op/onibus/layout/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/onibus/layout/new",
          name: "novo-layout",
          component: () => import("./views/cad_op/onibus/layout/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/onibus/layout/editar/:id",
          name: "editar-layout",
          props: true,
          component: () => import("./views/cad_op/onibus/layout/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/conexoes/gerenciar",
          name: "gerenciar-conexoes",
          component: () => import("./views/cad_op/conexoes/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/conexoes/editar/:id",
          name: "editar-conexoes",
          props: true,
          component: () => import("./views/cad_op/conexoes/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/rtrechos/gerenciar",
          name: "gerenciar-rtrecho",
          component: () => import("./views/cad_op/rtrecho/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/rtrechos/new",
          name: "editar-rtrecho",
          props: true,
          component: () => import("./views/cad_op/rtrecho/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/rtrechos/editar/:id",
          name: "editar-rtrecho",
          props: true,
          component: () => import("./views/cad_op/rtrecho/Create.vue"),
        },
        {
          path: "/cadastros/orgao/gerenciar",
          name: "gerenciar-orgao",
          component: () => import("./views/cad_op/orgao/Main.vue"),
        },
        {
          path: "/cadastros/orgao/new",
          name: "criar-orgao",
          component: () => import("./views/cad_op/orgao/Create.vue"),
        },
        {
          path: "/cadastros/orgao/editar/:id",
          name: "editar-orgao",
          props: true,
          component: () => import("./views/cad_op/orgao/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/viagens/gerenciar",
          name: "gerenciar-viagens",
          component: () => import("./views/cad_op/viagens/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/viagens/new",
          name: "nova-viajem",
          component: () => import("./views/cad_op/viagens/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/viagens/editar/:id",
          name: "editar-viajem",
          props: true,
          component: () => import("./views/cad_op/viagens/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/gerar-gratuidades/gerenciar",
          name: "gerenciar-gratuidades-geradas",
          component: () => import("./views/cad_op/gerar_gratuidades/Main.vue"),
        },
        {
          path: "/cadastros-operacionais/gerar-gratuidades/new",
          name: "nova-gratuidades-geradas",
          component: () =>
            import("./views/cad_op/gerar_gratuidades/Create.vue"),
        },
        {
          path: "/cadastros-operacionais/gerar-gratuidades/editar/:id",
          name: "editar-gratuidades-geradas",
          props: true,
          component: () =>
            import("./views/cad_op/gerar_gratuidades/Create.vue"),
        },
      ],
    },
    {
      path: "/pdv",
      name: "pdv",
      component: () => import("./views/pdv/Index.vue"),
      children: [
        {
          path: "/pdv",
          name: "venda",
          component: () => import("./views/pdv/Main.vue"),
        },
        {
          path: "/remarcar/:chBPe/:nProt/:tpAmb/:idOrigem/:idDestino/:estado/:idBpe/:idTipoVenda/:descTipoVenda/:idClient",
          name: "remarcacao-de-passagem",
          props: true,
          component: () => import("./views/pdv/Remarcacao.vue"),
        },
        {
          path: "/pdv/passagens/consultar",
          name: "consultar-passagens",
          component: () => import("./views/pdv/passagens/Main.vue"),
        },
        {
          path: "/pdv/emitir-excesso-bagagem",
          name: "excesso-de-bagagem",
          component: () => import("./views/pdv/excesso_bagagem/Main.vue"),
        },
        {
          path: "/pdv/viagens/confirmar-embarque",
          name: "confirmar-embarque",
          props: true,
          component: () => import("./views/pdv/embarque/Confirmar.vue"),
        },
        {
          path: "/pdv/viagens/mapa-de-viagem",
          name: "mapa-de-viagem",
          props: true,
          component: () => import("./views/pdv/mapa_de_viagem/Main.vue"),
        },
      ],
    },
    {
      path: "/reportbpe",
      name: "aliquota",
      component: () => import("./views/parametros/report_bpe/Main.vue"),
    },
    {
      path: "/parametros/aliquotas",
      name: "aliquota",
      component: () => import("./views/parametros/aliquotas/Main.vue"),
    },
    {
      path: "/parametros/aliquotas/new",
      name: "nova-aliquota",
      component: () => import("./views/parametros/aliquotas/Create.vue"),
    },
    {
      path: "/parametros/aliquotas/editar/:id",
      name: "editar-aliquota",
      props: true,
      component: () => import("./views/parametros/aliquotas/Create.vue"),
    },
    {
      path: "/parametros/bpe",
      name: "bpe",
      component: () => import("./views/parametros/Bpe.vue"),
    },
    {
      path: "/parametros/cte",
      name: "cte",
      component: () => import("./views/parametros/cte/Main.vue"),
    },
    {
      path: "/parametros/grupos",
      name: "Grupos de Usuários",
      component: () => import("./views/parametros/grupos/Main.vue"),
    },
    {
      path: "/parametros/grupos/new",
      name: "Novo Grupo",
      component: () => import("./views/parametros/grupos/Create.vue"),
    },
    {
      path: "/parametros/grupos/editar/:id",
      name: "Alterar Grupo",
      props: true,
      component: () => import("./views/parametros/grupos/Create.vue"),
    },
    {
      path: "/pdv/financeiro/caixa-bilheteiro",
      name: "caixa-bilheteiro",
      component: () => import("./views/pdv/financeiro/caixa/Main.vue"),
    },
    {
      path: "/pdv/financeiro/bilhete/:id",
      name: "bilhete",
      props: true,
      component: () => import("./views/pdv/financeiro/caixa/Bilhete.vue"),
    },
    {
      path: "/pdv/financeiro/encomenda/:id",
      name: "encomenda-detalhes",
      props: true,
      component: () => import("./views/pdv/financeiro/caixa/Encomenda.vue"),
    },
    {
      path: "/livro-caixa",
      name: "Livro Caixa",
      component: () => import("./views/financeiro/caixa/Main.vue"),
    },
    {
      path: "/livro-caixa/transferencias-entre-agencias",
      name: "Transferências",
      component: () =>
        import("./views/financeiro/transferencias_entre_agencias/Main.vue"),
    },
    {
      path: "/livro-caixa/encomendas",
      name: "Livro Caixa - Encomendas",
      component: () => import("./views/financeiro/caixa/encomendas/Main.vue"),
    },
    {
      path: "/livro-caixa/encomendas-detalhados",
      name: "Livro Caixa - Encomendas Detalhadas",
      component: () =>
        import("./views/financeiro/caixa/encomendasDetalhadas/Main.vue"),
    },
    {
      path: "/livro-caixa/encomendas-pagas-e-nao-pagas",
      name: "Livro Caixa - Encomendas Pagas e Não Pagas",
      component: () =>
        import("./views/financeiro/caixa/encomendasPagasNaoPagas/Main.vue"),
    },
    {
      path: "/livro-caixa/sintetico",
      name: "Livro Caixa - Relatório Sintético",
      component: () => import("./views/financeiro/caixa/sintetico/Main.vue"),
    },
    {
      path: "/livro-caixa/fechamento",
      name: "Livro Caixa - Relatório Fechamento",
      component: () => import("./views/financeiro/caixa/fechamento/Main.vue"),
    },
    {
      path: "/livro-caixa/bpe",
      name: "Livro Caixa - Bpe Emitidos",
      component: () => import("./views/financeiro/caixa/bpe/Main.vue"),
    },
    {
      path: "/livro-caixa/comissoes",
      name: "Comissōes",
      component: () => import("./views/financeiro/comissoes/Index.vue"),
    },
    {
      path: "/livro-caixa/comissoes-encomendas",
      name: "Comissoes-ecomendas",
      component: () =>
        import("./views/financeiro/comissoes/encomendas/Main.vue"),
    },
    {
      path: "/livro-caixa/comissoes-passagens",
      name: "Comissoes-passgens",
      component: () =>
        import("./views/financeiro/comissoes/passagens/Main.vue"),
    },
    {
      path: "/livro-caixa/lancamentos",
      name: "Lançamentos",
      component: () => import("./views/financeiro/lancamentos/Main.vue"),
    },
    {
      path: "/pdv/gestao-bpe",
      name: "gestao-bpes",
      props: true,
      component: () => import("./views/pdv/passagens/GestaoBPe.vue"),
    },
    {
      path: "/pdv/gestao-bpe-pending",
      name: "gestao-bpes-pending",
      props: true,
      component: () => import("./views/pdv/passagens/GestaoBPePending.vue"),
    },
    {
      path: "/pdv/ajuda",
      name: "ajuda",
      component: () => import("./views/pdv/Index.vue"),
      children: [
        {
          path: "/pdv/ajuda/videos",
          name: "Videos",
          component: () => import("./views/ajuda/videos/Main.vue"),
        },
      ],
    },
    {
      path: "/passagens-vendidas",
      name: "Passagens Vendidas",
      component: () => import("./views/financeiro/passagensVendidas.vue"),
    },
  ],
});
