import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
import vuetify from "./plugins/vuetify";
import diVueMask from "di-vue-mask";
import "./plugins/axios";
import "./style.css";
import "./plugins/print";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;
Vue.use(diVueMask, VueApexCharts);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
