import Vue from "vue";
import axios from "axios";
import { apiUrlByEnviroment } from "../utils";
axios.defaults.headers["Content-Type"] = "application/json";
const token = sessionStorage.getItem("auth-token");

Vue.use({
  install(Vue) {
    Vue.prototype.$http = axios.create({
      baseURL: apiUrlByEnviroment[process.env.NODE_ENV.trim()],
      headers: { Authorization: `Bearer ${token}` },
    });
  },
});
