export default {
  closeMenu(state, payload) {
    state.menu.drawer = payload;
  },
  closeLoader(state, payload) {
    state.run.loader = payload;
  },
  UserLogin(state, payload) {
    state.operador = payload;
  },
  setAgency(state, payload) {
    state.agency = payload;
  },
  clearSelectedPackpages(state, payload) {
    state.selectedPackpages = payload;
  },
  showMessage(state, payload) {
    state.message = payload;
  },
  updateCurrentCashboxState(state, payload) {
    state.cashbox = payload;
  },
  updateShowModalDriverReleases(state, payload) {
    state.showModalDriverReleases = payload;
  },
  showCashboxModalOpenCashbox(state, payload) {
    state.showCashboxModalOpenCashbox = payload;
  },
  showCashboxModalReopenCashbox(state, payload) {
    state.showCashboxModalReopenCashbox = payload;
  },
  showMessagecashboxLastOpened(state, payload) {
    state.showMessagecashboxLastOpened = payload;
  },
  showMessageCashboxNotOpenToday(state, payload) {
    state.showMessageCashboxNotOpenToday = payload;
  },
  setLargeHeader(state, payload) {
    state.largeHeader = payload;
  },
};
