export default {
  largeHeader: false,
  selectedPackpages: [],
  showModalDriverReleases: false,
  message: {
    timeout: 3000,
    show: false,
    color: "primary",
    text: "",
  },
  showMessagecashboxLastOpened: false,
  showMessageCashboxNotOpenToday: false,
  showCashboxModalOpenCashbox: false,
  showCashboxModalReopenCashbox: false,
  menu: {
    drawer: true,
  },
  cashbox: {
    status: false,
    id: null,
  },
  operador: [],
  agency: {},
  configs: {
    appName: "Transbraz - SGVP",
    poltrona:
      "<svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 200 200' style='image-rendering:optimizeQuality;shape-rendering:geometricPrecision;text-rendering:geometricPrecision'><g id='Camada_x0020_1'><metadata id='CorelCorpID_0Corel-Layer'/><g id='_2177833614976'><path class='fil0' d='M34 100c0 12 3 31 5 42 2 7 2 7 5 12l24 0c3-16 18-12 43-12 22 0 12-4 12-15 0-10 2-16 2-26 0-12-4-23-2-32 3-13 6-11-37-11-7 0-9 1-13-2-3-4-4-5-5-10-5 0-18-1-22 0-10 3-12 46-12 54z'/><path class='fil0' d='M79 42c0 6 0 3 3 7l47 0c2 3 3 7 5 11 3 8 2 5 0 12-2 5 2 20 2 28 0 10-2 17-2 28 0 6 3 1 0 11-2 5-3 8-5 12l-47 0c-3 4-3 1-3 7 0 3 2 6 5 6l56 0c10 0 11-16 15-23 3-9 9-3 10-29 1-15 1-1 0-21-1-9 0-20-7-27-5-6-2-4-7-15-1-4-4-13-10-13l-57 0c-3 0-5 3-5 6z'/></g></g></svg>",
    poltrona_idoso:
      "<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='16.9332mm' height='16.9332mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 1693 1693'><g id='Camada_x0020_1'><metadata id='CorelCorpID_0Corel-Layer'/><g id='_1761432315648'><path class='fil0' d='M290 847c0,98 21,261 44,358 13,56 11,59 38,99l201 0c31,-132 159,-102 366,-101 186,1 100,-33 100,-128 0,-87 19,-138 19,-219 0,-99 -34,-192 -16,-272 25,-113 47,-97 -313,-94 -56,1 -75,7 -108,-20 -32,-26 -38,-37 -48,-80 -36,0 -153,-5 -181,1 -85,20 -102,388 -102,456z'/><path class='fil0' d='M665 353c0,50 5,32 27,64l402 0c14,27 26,59 38,90 25,67 15,41 -2,102 -11,40 19,166 19,238 0,88 -18,147 -18,237 0,49 33,12 4,95 -15,42 -23,63 -41,97l-402 0c-22,33 -27,15 -27,64 0,21 24,46 45,46l476 0c87,0 96,-133 124,-196 31,-72 76,-21 86,-243 5,-127 13,-8 0,-174 -5,-78 -2,-175 -55,-228 -49,-50 -24,-34 -61,-131 -12,-29 -34,-107 -85,-107l-485 0c-21,0 -45,25 -45,46z'/></g><g id='_1761347780032'><g><g><path class='fil1' d='M811 776c-21,4 -52,11 -67,-11 -8,-12 -14,-27 -19,-42 11,-17 11,-42 1,-59 -16,-28 -48,-31 -75,-19 -71,31 -112,116 -129,189 -4,8 -5,18 -1,27 1,9 4,16 9,23 -10,68 -29,141 -67,198 -25,38 37,74 62,36 34,-52 54,-113 67,-175 5,8 11,15 15,24 16,31 -20,69 -38,90 -30,35 20,86 50,51 34,-40 72,-89 61,-145 -7,-34 -29,-64 -53,-91 0,-1 0,-2 1,-4 8,-39 23,-85 54,-112 6,17 14,34 26,47 26,31 66,31 103,25l0 312 13 1 0 -315c0,0 1,0 1,0 33,-7 19,-57 -14,-50z'/><circle class='fil1' cx='751' cy='600' r='54'/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g></g></svg>",
    poltrona_estudante:
      "<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='16.9332mm' height='16.9332mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 1693 1693' xmlns:xlink='http://www.w3.org/1999/xlink' enable-background='new 0 0 212.755 212.755'><g id='Camada_x0020_1'><metadata id='CorelCorpID_0Corel-Layer'/><g id='_2344965454048'><path class='fil0' d='M290 847c0,98 21,261 44,358 13,56 11,59 38,99l201 0c31,-132 159,-102 366,-101 186,1 100,-33 100,-128 0,-87 19,-138 19,-219 0,-99 -34,-192 -16,-272 25,-113 47,-97 -313,-94 -56,1 -75,7 -108,-20 -32,-26 -38,-37 -48,-80 -36,0 -153,-5 -181,1 -85,20 -102,388 -102,456z'/><path class='fil0' d='M665 353c0,50 5,32 27,64l402 0c14,27 26,59 38,90 25,67 15,41 -2,102 -11,40 19,166 19,238 0,88 -18,147 -18,237 0,49 33,12 4,95 -15,42 -23,63 -41,97l-402 0c-22,33 -27,15 -27,64 0,21 24,46 45,46l476 0c87,0 96,-133 124,-196 31,-72 76,-21 86,-243 5,-127 13,-8 0,-174 -5,-78 -2,-175 -55,-228 -49,-50 -24,-34 -61,-131 -12,-29 -34,-107 -85,-107l-485 0c-21,0 -45,25 -45,46z'/></g><g id='_2344965449632'><g><g><path class='fil1' d='M519 855l0 89c0,0 85,-36 161,-36 75,0 160,36 160,36l0 -90c0,0 -81,-43 -162,-43 -80,0 -159,44 -159,44z'/><path class='fil1' d='M930 834l32 -17 -32 -18 0 -8c0,0 9,-46 -42,-22 -2,1 -4,3 -4,4l-211 -117 -277 159 104 47 0 -15c0,0 88,-49 178,-49 90,0 181,47 181,47l0 27 57 -31c0,36 0,113 0,126l-19 0 0 45 26 -18 28 18 0 -45 -21 0 0 -133 0 0zm-14 -51c0,1 0,4 0,8l-16 -9c6,-4 16,-10 16,1zm-5 49c-8,0 -14,-6 -14,-13 0,-8 6,-14 14,-14 7,0 13,6 13,14 0,7 -6,13 -13,13z'/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g></g></svg>",
    poltrona_deficiente:
      "<svg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='16.9332mm' height='16.9332mm' version='1.1' style='shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd' viewBox='0 0 1693 1693' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Camada_x0020_1'><metadata id='CorelCorpID_0Corel-Layer'/><g id='_1761355086032'><path class='fil0' d='M290 847c0,98 21,261 44,358 13,56 11,59 38,99l201 0c31,-132 159,-102 366,-101 186,1 100,-33 100,-128 0,-87 19,-138 19,-219 0,-99 -34,-192 -16,-272 25,-113 47,-97 -313,-94 -56,1 -75,7 -108,-20 -32,-26 -38,-37 -48,-80 -36,0 -153,-5 -181,1 -85,20 -102,388 -102,456z'/><path class='fil0' d='M665 353c0,50 5,32 27,64l402 0c14,27 26,59 38,90 25,67 15,41 -2,102 -11,40 19,166 19,238 0,88 -18,147 -18,237 0,49 33,12 4,95 -15,42 -23,63 -41,97l-402 0c-22,33 -27,15 -27,64 0,21 24,46 45,46l476 0c87,0 96,-133 124,-196 31,-72 76,-21 86,-243 5,-127 13,-8 0,-174 -5,-78 -2,-175 -55,-228 -49,-50 -24,-34 -61,-131 -12,-29 -34,-107 -85,-107l-485 0c-21,0 -45,25 -45,46z'/></g><g id='_1762871959648'><g id='XMLID_28_'><path id='XMLID_31_' class='fil1' d='M635 674c31,0 55,-25 55,-55 0,-31 -24,-56 -55,-56 -30,0 -55,25 -55,56 0,30 25,55 55,55z'/><path id='XMLID_30_' class='fil1' d='M720 987c-14,51 -61,88 -117,88 -67,0 -122,-55 -122,-122 0,-50 30,-93 73,-112l0 -51c-70,21 -122,86 -122,163 0,94 77,171 171,171 69,0 128,-41 155,-99l-19 -38c-6,0 -12,0 -19,0z'/><path id='XMLID_29_' class='fil1' d='M881 1052l-74 -145c-5,-11 -16,-18 -29,-18l-94 0 0 -21 81 0c8,0 15,-5 20,-11 3,-4 5,-8 5,-14 0,-13 -11,-24 -25,-24l-49 0 -32 0 0 -73c0,-21 -15,-49 -49,-49 -27,0 -49,22 -49,49l0 158c0,28 23,50 50,50l89 0 34 0 65 128c5,11 17,18 29,18 4,0 9,-2 14,-4 16,-8 23,-28 14,-44z'/></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g></g></svg>",
  },
  run: {
    loader: false,
  },
  paises: [
    "Afeganistão",
    "África do Sul",
    "Akrotiri",
    "Albânia",
    "Alemanha",
    "Andorra",
    "Angola",
    "Anguila",
    "Antárctida",
    "Antígua e Barbuda",
    "Arábia Saudita",
    "Arctic Ocean",
    "Argélia",
    "Argentina",
    "Arménia",
    "Aruba",
    "Ashmore and Cartier Islands",
    "Atlantic Ocean",
    "Austrália",
    "Áustria",
    "Azerbaijão",
    "Baamas",
    "Bangladeche",
    "Barbados",
    "Barém",
    "Bélgica",
    "Belize",
    "Benim",
    "Bermudas",
    "Bielorrússia",
    "Birmânia",
    "Bolívia",
    "Bósnia e Herzegovina",
    "Botsuana",
    "Brasil",
    "Brunei",
    "Bulgária",
    "Burquina Faso",
    "Burúndi",
    "Butão",
    "Cabo Verde",
    "Camarões",
    "Camboja",
    "Canadá",
    "Catar",
    "Cazaquistão",
    "Chade",
    "Chile",
    "China",
    "Chipre",
    "Clipperton Island",
    "Colômbia",
    "Comores",
    "Congo-Brazzaville",
    "Congo-Kinshasa",
    "Coral Sea Islands",
    "Coreia do Norte",
    "Coreia do Sul",
    "Costa do Marfim",
    "Costa Rica",
    "Croácia",
    "Cuba",
    "Curacao",
    "Dhekelia",
    "Dinamarca",
    "Domínica",
    "Egipto",
    "Emiratos Árabes Unidos",
    "Equador",
    "Eritreia",
    "Eslováquia",
    "Eslovénia",
    "Espanha",
    "Estados Unidos",
    "Estónia",
    "Etiópia",
    "Faroé",
    "Fiji",
    "Filipinas",
    "Finlândia",
    "França",
    "Gabão",
    "Gâmbia",
    "Gana",
    "Gaza Strip",
    "Geórgia",
    "Geórgia do Sul e Sandwich do Sul",
    "Gibraltar",
    "Granada",
    "Grécia",
    "Gronelândia",
    "Guame",
    "Guatemala",
    "Guernsey",
    "Guiana",
    "Guiné",
    "Guiné Equatorial",
    "Guiné-Bissau",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungria",
    "Iémen",
    "Ilha Bouvet",
    "Ilha do Natal",
    "Ilha Norfolk",
    "Ilhas Caimão",
    "Ilhas Cook",
    "Ilhas dos Cocos",
    "Ilhas Falkland",
    "Ilhas Heard e McDonald",
    "Ilhas Marshall",
    "Ilhas Salomão",
    "Ilhas Turcas e Caicos",
    "Ilhas Virgens Americanas",
    "Ilhas Virgens Britânicas",
    "Índia",
    "Indian Ocean",
    "Indonésia",
    "Irão",
    "Iraque",
    "Irlanda",
    "Islândia",
    "Israel",
    "Itália",
    "Jamaica",
    "Jan Mayen",
    "Japão",
    "Jersey",
    "Jibuti",
    "Jordânia",
    "Kosovo",
    "Kuwait",
    "Laos",
    "Lesoto",
    "Letónia",
    "Líbano",
    "Libéria",
    "Líbia",
    "Listenstaine",
    "Lituânia",
    "Luxemburgo",
    "Macau",
    "Macedónia",
    "Madagáscar",
    "Malásia",
    "Malávi",
    "Maldivas",
    "Mali",
    "Malta",
    "Man, Isle of",
    "Marianas do Norte",
    "Marrocos",
    "Maurícia",
    "Mauritânia",
    "México",
    "Micronésia",
    "Moçambique",
    "Moldávia",
    "Mónaco",
    "Mongólia",
    "Monserrate",
    "Montenegro",
    "Mundo",
    "Namíbia",
    "Nauru",
    "Navassa Island",
    "Nepal",
    "Nicarágua",
    "Níger",
    "Nigéria",
    "Niue",
    "Noruega",
    "Nova Caledónia",
    "Nova Zelândia",
    "Omã",
    "Pacific Ocean",
    "Países Baixos",
    "Palau",
    "Panamá",
    "Papua-Nova Guiné",
    "Paquistão",
    "Paracel Islands",
    "Paraguai",
    "Peru",
    "Pitcairn",
    "Polinésia Francesa",
    "Polónia",
    "Porto Rico",
    "Portugal",
    "Quénia",
    "Quirguizistão",
    "Quiribáti",
    "Reino Unido",
    "República Centro-Africana",
    "República Checa",
    "República Dominicana",
    "Roménia",
    "Ruanda",
    "Rússia",
    "Salvador",
    "Samoa",
    "Samoa Americana",
    "Santa Helena",
    "Santa Lúcia",
    "São Bartolomeu",
    "São Cristóvão e Neves",
    "São Marinho",
    "São Martinho",
    "São Pedro e Miquelon",
    "São Tomé e Príncipe",
    "São Vicente e Granadinas",
    "Sara Ocidental",
    "Seicheles",
    "Senegal",
    "Serra Leoa",
    "Sérvia",
    "Singapura",
    "Sint Maarten",
    "Síria",
    "Somália",
    "Southern Ocean",
    "Spratly Islands",
    "Sri Lanca",
    "Suazilândia",
    "Sudão",
    "Sudão do Sul",
    "Suécia",
    "Suíça",
    "Suriname",
    "Svalbard e Jan Mayen",
    "Tailândia",
    "Taiwan",
    "Tajiquistão",
    "Tanzânia",
    "Território Britânico do Oceano Índico",
    "Territórios Austrais Franceses",
    "Timor Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trindade e Tobago",
    "Tunísia",
    "Turquemenistão",
    "Turquia",
    "Tuvalu",
    "Ucrânia",
    "Uganda",
    "União Europeia",
    "Uruguai",
    "Usbequistão",
    "Vanuatu",
    "Vaticano",
    "Venezuela",
    "Vietname",
    "Wake Island",
    "Wallis e Futuna",
    "West Bank",
    "Zâmbia",
    "Zimbabué",
  ],
};
