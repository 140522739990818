export default {
  getMenuState(state) {
    return state.menu.drawer;
  },
  getAppName(state) {
    return state.configs.appName;
  },
  getPaises(state) {
    return state.paises;
  },
  getLoaderState(state) {
    return state.run.loader;
  },
  getPoltrona(state) {
    return state.configs.poltrona;
  },
  getPoltronaIdoso(state) {
    return state.configs.poltrona_idoso;
  },
  getPoltronaEstudante(state) {
    return state.configs.poltrona_estudante;
  },
  getPoltronaDeficiente(state) {
    return state.configs.poltrona_deficiente;
  },
  getOpAgency(state) {
    return state.operador.id_agencia;
  },
  getAgency(state) {
    return state.agency;
  },
  getOpCompany(state) {
    return state.operador.id_empresa;
  },
  getOperador(state) {
    return state.operador.id_operador;
  },
  getOperadorData(state) {
    return state.operador;
  },
  getOperadorLevel(state) {
    return state.operador.level;
  },
  getselectedPackpages(state) {
    return state.selectedPackpages;
  },
  getMessage(state) {
    return state.message;
  },
  getCurrentCashboxState(state) {
    return state.cashbox.status;
  },
  getCurrentCashboxId(state) {
    return state.cashbox.id;
  },
  getShowModalDriverReleases(state) {
    return state.ShowModalDriverReleases;
  },
  getShowMessagecashboxLastOpened(state) {
    return state.showMessagecashboxLastOpened;
  },
  getShowCashboxModalReopenCashbox(state) {
    return state.showCashboxModalReopenCashbox;
  },
  getshowCashboxModalOpenCashbox(state) {
    return state.showCashboxModalOpenCashbox;
  },
  getshowMessageCashboxNotOpenToday(state) {
    return state.showCashboxModalOpenCashbox;
  },
  getLargeHeader(state) {
    return state.largeHeader;
  },
};
