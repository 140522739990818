<template>
  <div style="position: relative">
    <Menu />
    <HeaderContainer :largeHeader="$store.getters.getLargeHeader">
      <HeaderContent>
        <div style="display: flex; flex-direction: row; align-items: center">
          <v-app-bar-nav-icon @click.stop="menu()"></v-app-bar-nav-icon>
          <v-img width="200" src="@/assets/logoWhite.png" alt="Vuetify" class="ml-3" />
        </div>
        <HeaderButtonsAndTexts>
          <v-tooltip bottom v-if="$store.getters.getOperadorLevel == 1"
            :color="count > 0 ? 'yellow darken-4' : 'green darken-2'" style="z-index: 999">
            <template v-slot:activator="{ on }">
              <HeaderTexts>
                <v-btn icon text dark v-on="on" to="/livro-caixa/encomendas">
                  <v-icon color="yellow darken-1" v-if="count > 0">
                    mdi-bell-alert
                  </v-icon>
                  <v-icon color="#fff" v-else> mdi-bell-ring </v-icon>
                </v-btn>
                <HeaderTexts><span>|</span></HeaderTexts>
              </HeaderTexts>
            </template>
            <HeaderTexts>{{
              count > 0
              ? `${count} encomendas pendentes de confirmação!`
              : `Nenhuma encomenda pendente de aprovação.`
            }}</HeaderTexts>
          </v-tooltip>
          <HeaderTexts>
            <v-btn text dark c @click="hotReload()">
              <span style="padding: 2px"> LIMPAR CACHE</span>
              <v-icon size="21">mdi-reload</v-icon>
            </v-btn>
          </HeaderTexts>
          <HeaderTexts><span>|</span></HeaderTexts>
          <HeaderTexts><span>Olá {{ $store.getters.getOperadorData.login }}</span>
          </HeaderTexts>
          <HeaderTexts><span>|</span></HeaderTexts>
          <HeaderTexts><span>{{ agencia.nome }}</span></HeaderTexts>
          <HeaderTexts><span>|</span></HeaderTexts>
          <HeaderTexts>
            <v-btn dark text @click="logout()">
              SAIR <v-icon>mdi-power</v-icon>
            </v-btn>
          </HeaderTexts>
        </HeaderButtonsAndTexts>
      </HeaderContent>
    </HeaderContainer>
  </div>
</template>

<style scoped>
.v-btn>.v-btn__content .v-icon {
  color: #ffffff;
}
</style>
<script>
import {
  HeaderContainer,
  HeaderTexts,
  HeaderContent,
  HeaderButtonsAndTexts,
} from "./styled";
import Menu from "../Menu";
export default {
  components: {
    HeaderContainer,
    HeaderContent,
    HeaderButtonsAndTexts,
    HeaderTexts,
    Menu,
  },
  name: "Header",
  data() {
    return {
      now: new Date(),
      agencia: [],
      count: 0,
    };
  },
  created() {
    this.clearCacheOnLoad();
    setTimeout(() => {
      this.getAgency();
      this.getPackpagesOfTheAgency();
    }, 2000);
  },
  methods: {
    // Limpa o cache quando a página é carregada
    async clearCacheOnLoad() {
      if ("caches" in window) {
        try {
          const cacheNames = await caches.keys();
          await Promise.all(
            cacheNames.map((cacheName) => {
              return caches.delete(cacheName);
            })
          );
          console.log("Cache limpo com sucesso!");
        } catch (error) {
          console.error("Erro ao limpar cache:", error);
        }
      }
    },
    async hotReload() {
      await this.clearCacheOnLoad();
      window.location.reload(true);
    },
    logout() {
      let c = confirm("Deseja sair do aplicativo?");
      if (c) {
        sessionStorage.removeItem("auth-token");
        this.$store.dispatch("PersistUser", {});
        location.reload(1);
      }
    },
    menu() {
      this.$store.dispatch("activeMenu", !this.$store.getters.getMenuState);
    },
    getPackpagesOfTheAgency() {
      this.$http.get(`/encomendas/nao-pagas`).then((r) => {
        this.count = r.data.count;
        this.$store.dispatch("activeLoader", false);
      });
    },
    getAgency() {
      this.$http
        .get(`/agencias/${this.$store.getters.getOpAgency}`)
        .then((response) => {
          this.$store.dispatch("setAgency", response.data[0]);
          this.$store.dispatch("activeLoader", false);
        });
    },
  },
};
</script>
