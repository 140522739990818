import Vue from "vue";
import Vuetify from "vuetify/lib";
import { defaultTheme } from "../shared/theme";
Vue.use(Vuetify);

import pt from "vuetify/lib/locale/pt";

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "mdi",
  },
  theme: defaultTheme,
});
