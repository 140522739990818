<script>
import { apiUrlByEnviroment } from "../../utils";
export default {
  data: () => ({
    urlImages: "",
    userData: {
      login: "",
      grupo: "",
      id: "",
      foto: "",
    },
  }),
  mounted() {
    const usrData = this.$store.getters.getOperadorData;
    this.userData.login = usrData.login;
    this.userData.foto = usrData.foto;
    this.userData.name = usrData.name;
    this.userData.id = usrData.id_operador;
  },
  created() {
    this.urlImages = apiUrlByEnviroment[process.env.NODE_ENV.trim()];
    this.loadUserData();
  },
  methods: {
    avatarClick() {
      this.$router.push(`/cadastros/operadores/editar/${this.userData.id}`);
    },
    async loadUserData() {
      const { data } = await this.$http.get(`/operadores/${this.userData.id}`);

      this.$store.dispatch("PersistUser", {
        ...this.$store.getters.getOperadorData,
        foto: data[0].foto,
      });
      this.userData.foto = data[0].foto;
    },
  },
  watch: {
    "$store.state.operador": {
      handler: function (search) {
        this.loadUserData();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
