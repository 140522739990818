<template>
  <v-navigation-drawer
    v-model="$store.state.menu.drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    fixed
    temporary
    style="z-index: 999"
  >
    <UserAvatar />
    <v-list dense>
      <div v-for="(data, e) in permissoes" :key="e">
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children && item.alias == data.name"
            :key="item.text"
            v-model="item.model"
            :append-icon="item.model ? item.icon : item['icon-alt']"
            :prepend-icon="item.iconp"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </template>
            <div v-for="(datac, a) in data.children" :key="a">
              <div v-for="(child, i) in item.children" :key="i">
                <v-list-item v-if="child.text == datac.name" :to="child.route">
                  <v-list-item-action v-if="child.icon">
                    <v-icon>mdi-{{ child.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ child.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </div>
          </v-list-group>
          <v-list-item
            v-else-if="data.name == item.alias"
            :key="item.text"
            :to="item.route"
          >
            <v-list-item-action>
              <v-icon>mdi-{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import UserAvatar from "./userAvatar";
export default {
  props: {
    source: String,
  },
  components: {
    UserAvatar,
  },
  created() {
    this.compareMenu();
  },
  methods: {
    closeMenu() {
      this.$store.dispatch("activeMenu", false);
    },

    compareMenu() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(
          `/grupos/permissoes/${this.$store.getters.getOperadorData.id_grupo}`
        )
        .then((r) => {
          let d = r.data;
          // console.log(d);
          this.permissoes = d;
          this.$store.dispatch("activeLoader", false);
        });
    },
  },
  data: () => ({
    permissoes: [
      { name: "parametros", children: [{ name: "Grupos e Permissões" }] },
    ],
    items: [
      { icon: "apps", text: "PDV", alias: "pdv", route: "/pdv" },
      {
        icon: "apps",
        text: "Dashboard",
        alias: "dashboard",
        route: "/dashboard",
      },
      {
        icon: "folder-download-outline",
        text: "RBPE",
        alias: "reportbpe",
        route: "/reportbpe",
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Cadastros",
        iconp: "mdi-file-document-box-multiple-outline",
        model: false,
        alias: "cadastros",
        children: [
          {
            icon: "chevron-right",
            text: "Naturezas de Operações",
            route: "/cadastros/naturezas-operacao",
          },
          {
            icon: "chevron-right",
            text: "Clientes",
            route: "/cadastros/clientes",
          },
          {
            icon: "chevron-right",
            text: "Operadores",
            route: "/cadastros/operadores",
          },
          {
            icon: "chevron-right",
            text: "Formas de Pagamento",
            route: "/cadastros/formas-pagamento",
          },
          {
            icon: "chevron-right",
            text: "Tipos de Venda",
            route: "/cadastros/tipos-de-venda",
          },
          {
            icon: "chevron-right",
            text: "Tipos de Servico",
            route: "/cadastros/tipos-servico",
          },
          {
            icon: "chevron-right",
            text: "Orgão Regulador",
            route: "/cadastros/orgao/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Empresas",
            route: "/cadastros/empresas",
          },
          {
            icon: "chevron-right",
            text: "Motoristas",
            route: "/cadastros/motoristas",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Agências",
        iconp: "mdi-home-city-outline",
        model: false,
        alias: "agencias",
        children: [
          {
            icon: "chevron-right",
            text: "Gerenciar Agências",
            route: "/agencias/gerenciar",
          },
          // {
          //   icon: "coin",
          //   text: "Comissões",
          //   route: "/agencias/comissoes",
          // },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Encomendas",
        iconp: "mdi-truck-delivery-outline",
        model: false,
        alias: "encomendas",
        children: [
          {
            icon: "chevron-right",
            text: "Gerenciar Encomendas",
            route: "/encomendas/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Minhas Encomendas",
            route: "/encomendas/minhas-encomendas",
          },
          {
            icon: "chevron-right",
            text: "Encomenda CTE",
            route: "/encomendas-padrao-cte/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Movimentar Encomendas",
            route: "/encomendas/movimentar",
          },
          {
            icon: "chevron-right",
            text: "Clientes de Encomendas",
            route: "/encomendas/clientes",
          },
          {
            icon: "chevron-right",
            text: "Mapa de Encomendas",
            route: "/encomendas/mapa-de-encomendas",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Cad Operacionais",
        iconp: "mdi-file-tree",
        model: false,
        alias: "cadOperacionais",
        children: [
          {
            icon: "chevron-right",
            text: "Localidadaes",
            route: "/cadastros-operacionais/localidades/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Linhas",
            route: "/cadastros-operacionais/linhas/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Ônibus",
            route: "/cadastros-operacionais/onibus/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Layouts",
            route: "/cadastros-operacionais/onibus/layout/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Reaproveitamento de Trechos",
            route: "/cadastros-operacionais/rtrechos/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Viagens",
            route: "/cadastros-operacionais/viagens/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Gerar Gratuidades",
            route: "/cadastros-operacionais/gerar-gratuidades/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Checagem de Trechos",
            route: "/cadastros-operacionais/checktrechos/gerenciar",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Escalas",
        iconp: "mdi-home-city-outline",
        model: false,
        alias: "escalas",
        children: [
          {
            icon: "chevron-right",
            text: "Escala dos Ônibus",
            route: "/escalas/onibus",
          },
          {
            icon: "chevron-right",
            text: "Escala dos Motoristas",
            route: "/escalas/motoristas",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Financeiro",
        iconp: "mdi-currency-usd",
        model: false,
        alias: "financeiro",
        children: [
          {
            icon: "chevron-right",
            text: "Livro Caixa",
            route: "/livro-caixa",
          },
          {
            icon: "chevron-right",
            text: "Transferências",
            route: "/livro-caixa/transferencias-entre-agencias",
          },
          {
            icon: "chevron-right",
            text: "Caixa Encomendas",
            route: "/livro-caixa/encomendas",
          },
          {
            icon: "chevron-right",
            text: "Caixa Encomendas Detalhados",
            route: "/livro-caixa/encomendas-detalhados",
          },
          {
            icon: "chevron-right",
            text: "Comissões",
            route: "/livro-caixa/comissoes",
          },
          {
            icon: "chevron-right",
            text: "Comissões Passagens",
            route: "/livro-caixa/comissoes-passagens",
          },
          {
            icon: "chevron-right",
            text: "Comissões Encomendas",
            route: "/livro-caixa/comissoes-encomendas",
          },
          {
            icon: "chevron-right",
            text: "Encomendas",
            route: "/livro-caixa-encomendas",
          },
          {
            icon: "chevron-right",
            text: "Lançamentos",
            route: "/livro-caixa/lancamentos",
          },
          {
            icon: "chevron-right",
            text: "Encomendas Pagas e Não Pagas",
            route: "/livro-caixa/encomendas-pagas-e-nao-pagas",
          },
          {
            icon: "chevron-right",
            text: "Relatório Sintético",
            route: "/livro-caixa/sintetico",
          },
          {
            icon: "chevron-right",
            text: "Relatório Fechamento",
            route: "/livro-caixa/fechamento",
          },
          {
            icon: "chevron-right",
            text: "Bpe Emitidos",
            route: "/livro-caixa/bpe",
          },
          {
            icon: "chevron-right",
            text: "Passagens Vendidas",
            route: "/passagens-vendidas",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Preço",
        iconp: "mdi-coin",
        model: false,
        alias: "preco",
        children: [
          {
            icon: "chevron-right",
            text: "Tarifas",
            route: "/preco/tarifas",
          },
          {
            icon: "chevron-right",
            text: "Descontos",
            route: "/preco/descontos/gerenciar",
          },
          {
            icon: "chevron-right",
            text: "Gratuidades",
            route: "/preco/gratuidades/gerenciar",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Parâmetros",
        iconp: "mdi-settings",
        model: false,
        alias: "parametros",
        children: [
          {
            icon: "chevron-right",
            text: "Aliquotas",
            route: "/parametros/aliquotas",
          },
          {
            icon: "chevron-right",
            text: "Bp-e",
            route: "/parametros/bpe",
          },
          {
            icon: "chevron-right",
            text: "Ct-e",
            route: "/parametros/cte",
          },
          {
            icon: "chevron-right",
            text: "Grupos e Permissões",
            route: "/parametros/grupos",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        iconp: "mdi-wallet-travel",
        text: "Viagens",
        model: false,
        alias: "viagens",
        children: [
          {
            icon: "chevron-right",
            text: "Confirmar Embarque",
            route: "/pdv/viagens/confirmar-embarque",
          },
          {
            icon: "chevron-right",
            text: "Mapa de Viagem",
            route: "/pdv/viagens/mapa-de-viagem",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Passagens",
        iconp: "mdi-newspaper",
        model: false,
        alias: "passagens",
        children: [
          {
            icon: "chevron-right",
            text: "Consultar",
            route: "/pdv/passagens/consultar",
          },
          {
            icon: "chevron-right",
            text: "Excesso de Bagagem",
            route: "/pdv/emitir-excesso-bagagem",
          },
          {
            icon: "chevron-right",
            text: "Gerenciar BPe-s com Erros",
            route: "/pdv/gestao-bpe",
          },
          {
            icon: "chevron-right",
            text: "Gerenciar BPe-s Pedentes",
            route: "/pdv/gestao-bpe-pending",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Caixa",
        iconp: "mdi-coin-outline",
        model: false,
        alias: "caixa",
        children: [
          {
            icon: "chevron-right",
            text: "Caixa",
            route: "/pdv/financeiro/caixa-bilheteiro",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Vendas",
        iconp: "mdi-handshake",
        model: false,
        alias: "vendas",
        children: [
          {
            icon: "chevron-right",
            text: "Vendas",
            route: "/pdv/vendas/venda-manual",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Voucher",
        iconp: "mdi-web",
        model: false,
        alias: "voucher",
        children: [
          {
            icon: "chevron-right",
            text: "Voucher",
            route: "/pdv/voucher/troca-de-voucher",
          },
        ],
      },
      {
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Ajuda",
        iconp: "mdi-help",
        model: false,
        alias: "ajuda",
        children: [
          {
            icon: "chevron-right",
            text: "Videos",
            route: "/pdv/ajuda/videos",
          },
        ],
      },
      {
        icon: "chevron-right",
        text: "Meus Dados",
        alias: "usuario",
        route: "/pdv/usuario",
      },
    ],
  }),
};
</script>
