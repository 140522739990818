import styled from "vue-styled-components";
import { defaultTheme } from "../../shared/theme";
import { themeResolver } from "../../utils";

const hdrProps = { largeHeader: Boolean };
export const HeaderContainer = styled("div", hdrProps)`
  width: 100%;
  height: ${({ largeHeader }) => (largeHeader ? "127px" : "70px")};
  padding: ${({ largeHeader }) => (largeHeader ? "30px 15px" : "15px")};
  background-color: ${defaultTheme.themes[themeResolver()].primary};
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderButtonsAndTexts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
`;

export const HeaderTexts = styled.div`
  display: flex;
  align-items: center;
  span {
    padding: 0 5px;
    color: ${defaultTheme.themes[themeResolver()].contrastText};
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0;
  }
`;
