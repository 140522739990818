<template>
  <v-content>
    <v-container fluid>
      <v-row align="center" justify="center">
        <router-view />
      </v-row>
    </v-container>
  </v-content>
</template>
<script>
export default {
  name: "Main",
};
</script>
