export const themeResolver = () => {
  const prefesColorScheme = matchMedia("(prefers-color-scheme: light)");

  if (prefesColorScheme.matches) {
    return "dark";
  }
  return "light";
};

export const apiUrlByEnviroment = {
  development: process.env.VUE_APP_URL_DEV_API,
  production: process.env.VUE_APP_PROD_API,
};

export const api2UrlByEnviroment = {
  development: process.env.VUE_APP_APIV2_DEV,
  production: process.env.VUE_APP_APIV2_PROD,
};
