<template>
  <v-app>
    <v-overlay
      z-index="99999"
      :value="$store.getters.getLoaderState"
      class="text-center"
      color="#fbfbfb"
      opacity="1"
    >
      <v-progress-circular :size="240" :width="5" color="primary" indeterminate>
        <img src="./assets/logoBlue.png" alt srcset style="width: 180px" />
      </v-progress-circular>
      <h2 class="mt-5" style="color: #2054af">{{ loader }}</h2>
    </v-overlay>
    <Message />
    <template v-if="!showLogin">
      <Header />
      <Main />
    </template>
    <template v-else>
      <router-view />
    </template>
  </v-app>
</template>

<script>
import Header from "./components/Header/index.vue";
import Main from "./components/Main";
import Message from "./components/Message";
import decode from "jwt-decode";
export default {
  name: "App",
  components: {
    Message,
    Header,
    Main,
  },
  data: () => ({
    showLogin: false,
    redirect: "",
    loader: "Carregando.",
  }),
  beforeMount() {
    const token = sessionStorage.getItem("auth-token");
    if (!token) {
      this.$router.replace("/login");
      this.$store.dispatch("showMessage", {
        show: true,
        color: "error darken-1",
        text: "Token inválido, execute o login novamente!",
      });
      return;
    }

    const {
      id_operador,
      id_agencia,
      id_empresa,
      login,
      level,
      id_grupo,
      foto,
    } = decode(token);
    this.$store.dispatch("PersistUser", {
      id_operador,
      id_empresa,
      id_agencia,
      login,
      level,
      id_grupo,
      foto,
    });
  },
  mounted() {
    this.$store.dispatch("showMessage", { show: false });
    this.$store.dispatch("activeLoader", true);
    if (!sessionStorage.getItem("auth-token")) {
      this.showLogin = true;
      this.$router.push("/login");
      this.$store.dispatch("activeLoader", false);
    } else {
      this.showLogin = false;
      if (this.$store.getters.getOperadorData.id_grupo == 1) {
        this.$router.replace(`/pdv`);
      } else {
        this.$router.replace(`/dashboard`);
      }
      this.$http
        .get(
          `/grupos/permissoes/${this.$store.getters.getOperadorData.id_grupo}`
        )
        .then((r) => {
          this.$router.replace(`/${r.data[0].name}`);
          this.$store.dispatch("activeLoader", false);
        });
    }
    this.loaderText();
    document.onkeydown = function () {
      switch (event.keyCode) {
        case 116:
          event.returnValue = false;
          event.keyCode = 0;
          return false;
        case 82:
          if (event.ctrlKey) {
            event.returnValue = false;
            event.keyCode = 0;
            return false;
          }
      }
    };
  },
  methods: {
    loaderText() {
      setInterval(() => {
        this.loader = "Carregando...";
        setTimeout(() => {
          this.loader = "Carregando..";
          setTimeout(() => {
            this.loader = "Carregando.";
          }, 1000);
        }, 1000);
      }, 3000);
    },
  },
  watch: {
    "$route.path": {
      handler: function (search) {
        if (search == "/pdv" || search.includes("remarcar")) {
          this.$store.dispatch("setLargeHeader", true);
        } else {
          this.$store.dispatch("setLargeHeader", false);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

